.course-list {
    .course-card {
        border: 1px solid $gray-f2;
        border-radius: 8px;
        margin-bottom: 40px;
        box-shadow: 0 0 10px rgb(0 0 0 / 15%);

        .image {
            position: relative;

            img {
                max-width: 100%;
                height: auto;
                border-radius: 8px 8px 0 0;
            }

            .title {
                position: absolute;
                top: 0;
                display: flex;
                width: 100%;
                height: 100%;
                flex-wrap: wrap;
                align-content: center;
                border-radius: 8px 8px 0 0;
                background: linear-gradient(0deg, rgb(0 0 0 / 40%), rgb(0 0 0 / 40%));
                gap: 16px;
                text-align: center;

                > * {
                    flex-basis: 100%;
                    color: $white;
                }
            }
        }

        .content {
            padding: 24px 16px;
        }
    }

    @media #{$big-query} {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 40px;

        .course-card {
            flex-basis: 48%;
        }
    }
}

.course {
    img {
        max-width: 100%;
    }

    .content {
        .course-title {
            margin-top: 24px;
            text-align: center;

            h1 {
                margin-bottom: 8px;
                color: $primary;
            }
        }

        .content-block {
            margin-top: 24px;

            p {
                margin-bottom: 0;
            }
        }
    }

    @media #{$big-query} {
        display: flex;
        align-items: center;

        img {
            flex-basis: 66%;
        }

        .content {
            flex-basis: 33%;
            padding-left: 48px;

            .course-title {
                margin-top: 0;
                text-align: left;
            }
        }
    }
}

.event-list {
    .event-card {
        max-width: 343px;
        padding: 0;
        border: 1px solid $gray-f2;
        border-radius: 8px;
        margin-bottom: 16px;
        box-shadow: 0 0 10px rgb(0 0 0 / 15%);

        img {
            max-width: 343px;
            border-radius: 8px 8px 0 0;
        }

        .event-content {
            padding: 24px;

            h3 {
                margin-bottom: 20px;
            }

            h4 {
                color: #999;
                font-size: 16px;
            }

            p {
                line-height: 20px;
            }

            .schedule {
                color: #333;
                font-size: 14px;
            }

            .price {
                font-size: 16px;
                font-weight: bold;
            }

            .advice {
                color: #999;
                font-size: 14px;
                line-height: 16px;
            }

            .btn-contact {
                margin: 0 !important;
            }
        }
    }

    @media #{$big-query} {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;

        .event-card {
            flex-basis: 32%;
        }
    }
}
