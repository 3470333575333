.hero {
    &.fullwidth {
        .content {
            padding: 32px 24px;
        }

        .overlay {
            &::after {
                position: absolute;
                content: '';
                inset: 0;
            }

            &.overlay-top::after {
                background: linear-gradient(0deg, transparent 50%, rgb(0 0 0 / 30%));
            }

            &.overlay-center::after {
                background: rgb(0 0 0 / 20%);
            }

            &.overlay-bottom::after {
                background: linear-gradient(0deg, rgb(0 0 0 / 30%), transparent 50%);
            }
        }
    }

    &.split {
        max-width: 1512px;

        video {
            max-width: 864px;
        }

        .btn {
            width: 100%;
        }
    }

    img {
        height: 100%;
        object-fit: cover;
        object-position: right;
    }

    .icon {
        font-size: 24px;
    }

    @include media-breakpoint-up(sm) {
        &.fullwidth {
            .content {
                padding: 32px 48px;
            }

            .overlay::after {
                background: linear-gradient(90deg, rgb(0 0 0 / 20%), transparent 50%) !important;
            }
        }

        &.split {
            .image,
            .video {
                flex-basis: 64%;
            }

            .content {
                flex-basis: 36%;
            }

            .btn {
                width: auto;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        &.fullwidth {
            .content {
                padding: 32px 108px;
            }
        }

        &.split {
            img {
                height: 486px;
            }

            .image,
            .video {
                flex-basis: auto;
            }

            .content {
                flex: 1 0 450px;
            }
        }
    }
}
