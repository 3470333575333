//-----------------------------------------------------
// Bootstrap
//-----------------------------------------------------

// Bootstrap functions
@import 'bootstrap/scss/functions';

// Custom variables
@import './ui/base/colors';
@import './ui/base/spacers';
@import './ui/base/borders';
@import './ui/base/shadows';
@import './ui/base/position-values';
@import './ui/base/bootstrap-variables';

// Bootstrap variables
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';

// Custom maps
@import './ui/base/bootstrap-maps';

// Bootstrap configuration
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

// Custom utilities
@import './ui/base/bootstrap-utilities';

// Bootstrap layout & components
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/placeholders';
@import 'bootstrap/scss/progress';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/type';

// Helpers
@import 'bootstrap/scss/helpers';

// Utilities API
@import 'bootstrap/scss/utilities/api';

//-----------------------------------------------------
// Third party components
//-----------------------------------------------------

@import '~semantic-ui-css/components/flag.min.css';
@import '~keen-slider/keen-slider.min.css';

//-----------------------------------------------------
// New design components
//-----------------------------------------------------

// Base
@import './ui/base/animations';
@import './ui/base/icons';
@import './ui/base/layout';
@import './ui/base/typography';

// Layout
@import './ui/layout/footer';

// Components
@import './ui/components/breadcrumb';
@import './ui/components/buttons';
@import './ui/components/hero';
@import './ui/components/mobile-accordion';
@import './ui/components/post-header';
@import './ui/components/pre-header';
@import './ui/components/promotions';
@import './ui/components/tag';

//-----------------------------------------------------
// Custom styles
//-----------------------------------------------------

@import './base/variables';
@import './base/typography';
@import './base/icomoon';
@import './base/global';
@import './layout/header';
@import './components/hero-banner';
@import './components/newsletter';
@import './components/buttons';
@import './components/forms';
@import './components/modal';
@import './components/product-list';
@import './components/messages';
@import './components/labels';
@import './components/image-text';
@import './components/two-cols-list';
@import './components/top-banner';
@import './components/responsive-modal';
@import './components/holiday-modal';
@import './components/generated-content';
@import './components/slider';
@import './components/category-banner-grid';
@import './components/editorial-link';
@import './components/cart-summary-totals';
@import './blog/global';
@import './blog/featured-post-card';
@import './blog/top-banner';
@import './blog/text-blocks';
@import './blog/image-blocks';
@import './pages/product-index';
@import './pages/product-show';
@import './pages/customer-forms';
@import './pages/customer-account';
@import './pages/cart';
@import './pages/checkout';
@import './pages/thank-you';
@import './pages/help';
@import './pages/courses-events';
@import './pages/job-offers';
@import './pages/fronda-club';
@import './pages/our-stores';
@import './pages/error';
@import './pages/category-landings';
@import './pages/pets';
@import './pages/fronda-pro';
@import './pages/landing-editorial';
@import './dev/symfony-toolbar';
