.cart-summary {
    .cart-items {
        .item-row {
            .item {
                min-height: 236px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .cart-items {
            .item-row {
                &:last-child {
                    padding-bottom: 0 !important;
                    border-bottom: none !important;
                }

                .item {
                    min-height: auto;
                }
            }
        }
    }
}
