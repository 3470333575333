.btn {
    min-width: 160px;
    max-height: 44px;

    .icon {
        font-size: 20px;
    }
}

.btn-primary.light {
    @include button-variant(
        $green-600,
        $green-600,
        $white,
        $green-700,
        $green-700,
        $white,
        $green-400,
        $green-400,
        $white,
        $gray-400,
        $gray-400,
        $white
    )
}

.btn-primary.dark {
    @include button-variant(
        $white,
        $white,
        $green-800,
        $gray-200,
        $gray-200,
        $green-800,
        $gray-100,
        $gray-100,
        $green-800,
        $gray-400,
        $gray-400,
        $white
    )
}

.btn-secondary.light {
    @include button-variant(
        $white,
        $gray-500,
        $gray-900,
        $gray-100,
        $gray-900,
        $gray-900,
        $gray-200,
        $gray-900,
        $gray-900,
        $white,
        $gray-400,
        $gray-400
    )
}

.btn-secondary.dark {
    @include button-variant(
        inherit,
        $white,
        $white,
        rgba($gray-100, 0.3),
        $white,
        $white,
        rgba($gray-200, 0.5),
        $white,
        $white,
        inherit,
        rgba($white, 0.5),
        rgba($white, 0.5)
    )
}
