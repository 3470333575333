@import '@adyen/adyen-web/dist/adyen.css';

.checkout-header {
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header-contact {
        padding: 8px 0;

        .call-us,
        .time {
            display: none;
        }

        .btn-contact {
            padding: 6px 10px;

            .icon {
                font-size: 1.5em;
            }
        }
    }
}

.checkout-step {
    &.select-shipping,
    &.select-payment {
        .free-shipping {
            display: none;
        }
    }

    .steps-row {
        position: relative;
        overflow: hidden;

        .steps {
            position: relative;
            display: flex;
            margin-top: 16px;
            gap: 40px;

            &.select_shipping {
                right: 240px;
            }

            &.select_payment {
                right: 525px;
            }

            .step {
                position: relative;
                display: flex;
                min-width: 240px;
                flex: 0 0 auto;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                padding: 8px 24px;
                border-radius: 16px;
                color: $gray-99;

                &.completed {
                    background-color: $gray-f2;
                }

                &.active {
                    background-color: $primary;
                    color: $white;
                    pointer-events: none;

                    .icon {
                        color: $white;
                    }
                }

                &.disabled {
                    background-color: $extra-light-primary;
                    opacity: 1;
                    pointer-events: none;
                }

                &:not(:last-child)::after {
                    position: absolute;
                    top: 0;
                    right: -40px;
                    width: 40px;
                    height: 50%;
                    border-bottom: 4px solid $gray-f2;
                    content: '';
                }

                .icon {
                    flex-basis: 100%;
                    color: $gray-99;
                    text-align: center;
                }
            }
        }
    }

    .checkout-step-section {
        margin-top: 32px;

        .step-content {
            padding: 24px;
            border: 1px solid $gray-bf;
            border-radius: 8px;
            margin: 16px 0 32px;

            &.payments {
                margin: 16px 0;

                input[type='radio'] {
                    + label {
                        font-family: $font-base-bold;
                    }
                }

                .safe-payment {
                    .header {
                        display: flex;
                        gap: 8px;

                        .icon {
                            color: $success;
                            font-size: 24px;
                        }
                    }

                    .safe-payment-warning {
                        padding-left: 32px;

                        .safe-payment-warning-header {
                            font-size: $font-small;

                            .info {
                                color: $success;
                                cursor: pointer;
                            }
                        }

                        .safe-payment-warning-content {
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }
                }
            }

            .form-input {
                margin-bottom: 24px;
            }

            input,
            .input {
                width: 100%;
            }

            #scheduled-delivery-date {
                .inputs {
                    margin-bottom: 0;

                    .input {
                        flex-basis: 100%;
                        margin-top: 0;
                    }
                }
            }

            .gift-wrapper-message {
                .caption {
                    color: $gray-99;
                }

                textarea {
                    width: 50ch;
                    max-width: 100%;
                    border: none;
                    white-space: pre-wrap;
                }
            }
        }

        #checkout-customer-change-email {
            text-align: right;

            span {
                cursor: pointer;
                font-family: $font-base-bold;
                text-decoration: underline;
            }
        }

        #checkout-customer-login-form {
            .forgot-password {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 24px;
                gap: 5px;

                p {
                    margin-bottom: 0;
                }

                a {
                    color: $primary;
                    font-family: $font-base-bold;
                }
            }
        }
    }

    .customer-data-section {
        position: relative;
        padding: 16px 32px;
        border-radius: 8px;
        margin: 16px 0 32px;
        background-color: $gray-f2;

        .icon {
            position: absolute;
            top: 16px;
            right: 24px;
            cursor: pointer;
        }
    }

    .checkout-delivery-method-form {
        margin-top: 16px;
    }

    #checkout-billing-address-content {
        margin-top: 24px;
    }

    #checkout-gift-wrapper-container {
        .form-toggle-check {
            margin-bottom: 30px;
        }

        .form-input {
            margin-bottom: 24px;
        }
    }

    #checkout-legal-warning-container {
        margin-bottom: 32px;
    }

    #pickup-delivery-method-warning {
        display: flex;
        align-items: center;
        margin-top: 34px;
        color: $gray-33;
        gap: 12px;

        .icon {
            color: $success;
        }
    }

    .dropin-container {
        .adyen-checkout__payment-method__brands {
            display: none;
        }

        .adyen-checkout__button--pay {
            border: 2px solid #b43a5f;
            border-radius: 32px;
            background-color: $secondary;
            transition: none;

            &:hover {
                background-color: #872c47;
                box-shadow: unset;
            }
        }

        .adyen-checkout__applepay__button {
            appearance: -apple-pay-button;
        }
    }

    .actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 16px;

        > * {
            flex-basis: 100%;
        }

        [class^='btn-'] {
            min-width: 0;
        }

        .mobile-first {
            order: -1;
        }
    }

    .action-link {
        margin-top: 24px;
        color: $primary;
        font-family: $font-base-bold;
        text-align: center;
        text-decoration: underline;

        span {
            cursor: pointer;
        }
    }

    .payment-form {
        .actions {
            margin-top: 32px;
        }
    }

    .login-info {
        margin-top: 30px;
    }

    .order-summary {
        padding: 8px 24px 24px;
        border: 1px solid $gray-bf;
        border-radius: 8px;

        &.shipment-info {
            padding-top: 24px;

            .title {
                display: flex;
                justify-content: space-between;

                p {
                    margin-bottom: 0;
                    color: $gray-33;
                    font-family: $font-base-semi-bold;
                    font-size: 16px;
                    line-height: 20px;
                }

                a {
                    color: $success;
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .content {
                margin-top: 8px;
                font-size: 16px;
                line-height: 24px;

                p {
                    margin-bottom: 0;
                }
            }
        }

        .order-summary-section {
            border-bottom: 1px solid $gray-bf;

            &.cashback {
                .order-summary-row {
                    align-items: center;

                    .special-promotion-price {
                        margin-top: 0;
                    }
                }
            }

            &.order-total {
                border-bottom: none;

                .order-summary-row {
                    padding-bottom: 4px;
                }

                .tax {
                    padding: 0;

                    .title,
                    .content {
                        font-family: $font-base;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }

            &.products {
                border-bottom: 1px solid $gray-bf;

                .product-name {
                    flex-basis: 70%;
                    font-family: $font-base;
                }

                .content {
                    display: flex;
                    flex-basis: 30%;
                    justify-content: space-between;
                    gap: 8px;
                }
            }

            .order-summary-row {
                display: flex;
                justify-content: space-between;
                padding: 16px 0;
                gap: 12px;

                &.shipments {
                    display: block;
                }

                &.discount {
                    flex-wrap: wrap;
                    gap: 8px;

                    .content {
                        color: $error;
                    }

                    .promotion,
                    .description {
                        width: 100%;
                        color: $gray-33;
                    }

                    .promotion {
                        font-family: $font-base-semi-bold;
                    }

                    .description {
                        font-size: 14px;
                        line-height: 20px;

                        p {
                            margin-bottom: 0;
                        }
                    }
                }

                .title {
                    color: $gray-33;
                    font-family: $font-base-semi-bold;
                }

                .shipment-price {
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: space-between;

                    .title {
                        color: $gray-33;
                        font-family: $font-base-semi-bold;
                    }
                }

                .free-shipping {
                    margin-top: 16px;
                    font-size: $font-small;
                }

                .extra-shipping {
                    display: flex;
                    align-items: center;
                    margin-top: 16px;
                    font-family: $font-base-semi-bold;
                    font-size: $font-small;
                    gap: 8px;

                    .icon {
                        color: $warning;
                    }
                }
            }
        }

        .cashback-info {
            margin-top: 16px;
            font-size: $font-small;
            line-height: 20px;
        }
    }
}

.checkout-footer {
    margin-top: 40px;

    .subtitle {
        margin: 16px 0;
        color: $primary;
    }

    .content {
        > div {
            margin-bottom: 8px;
        }
    }

    .icon {
        font-size: 1.5em;
    }

    .row-icon,
    .contact {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .images {
        max-width: 250px;

        img {
            border-radius: 3px;
            margin-bottom: 8px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .checkout-header {
        padding-top: 5px;

        .header-contact {
            display: flex;
            flex: 1 1 auto;
            justify-content: flex-end;
            gap: 16px;

            .call-us,
            .time {
                display: block;
                font-size: $font-small;
            }

            .help {
                display: flex;
            }

            .help,
            .time {
                flex: 0 0 auto;
                align-items: center;
            }
        }
    }

    .checkout-step {
        .steps-row {
            .steps {
                justify-content: center;

                &.select_shipping,
                &.select_payment {
                    right: 0;
                }
            }
        }

        .checkout-delivery-method-form {
            input[type='radio'] {
                + label {
                    flex-wrap: nowrap;
                    text-align: left;
                }
            }
        }

        .actions {
            > * {
                flex-basis: auto;
            }

            .mobile-first {
                order: 0;
            }
        }

        .action-link {
            text-align: right;
        }

        #checkout-subscribe-newsletter-container {
            margin-bottom: 32px;
        }
    }

    .checkout-footer {
        padding-top: 32px;
        border-top: 1px solid $gray-bf;
        filter: drop-shadow(0 -2px 4px rgb(0 0 0 / 10%));
    }
}

@media (width <= 414px) {
    .checkout-header {
        .header-contact {
            .help {
                font-size: 14px;
            }
        }
    }

    #checkout-gift-wrapper-container {
        .step-content {
            padding: 12px 12px 0;

            .form-toggle-check {
                margin-bottom: 20px;
            }
        }
    }
}

@media (width <= 359px) {
    .checkout-header {
        .logo {
            width: 100%;
        }
    }
}
