@import 'icomoon-variables';

@font-face {
    font-display: block;
    font-family: '#{$icomoon-font-family}';
    font-style: normal;
    font-weight: normal;
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xh0y41');
    src:
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xh0y41#iefix') format('embedded-opentype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?xh0y41') format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?xh0y41') format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?xh0y41##{$icomoon-font-family}') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: never;
    text-transform: none;
}

.icon-gift-32 {
    &::before {
        content: $icon-gift-32;
    }
}

.icon-gift-40 {
    &::before {
        content: $icon-gift-40;
    }
}

.icon-leaf-32 {
    &::before {
        content: $icon-leaf-32;
    }
}

.icon-leaf-40 {
    &::before {
        content: $icon-leaf-40;
    }
}

.icon-shield-check-32 {
    &::before {
        content: $icon-shield-check-32;
    }
}

.icon-shield-check-40 {
    &::before {
        content: $icon-shield-check-40;
    }
}

.icon-truck-40 {
    &::before {
        content: $icon-truck-40;
    }
}

.icon-calendar-heart-32 {
    &::before {
        content: $icon-calendar-heart-32;
    }
}

.icon-gift-16 {
    &::before {
        content: $icon-gift-16;
    }
}

.icon-tree-pine-16 {
    &::before {
        content: $icon-tree-pine-16;
    }
}

.icon-tree-pine {
    &::before {
        content: $icon-tree-pine;
    }
}

.icon-calendar-heart {
    &::before {
        content: $icon-calendar-heart;
    }
}

.icon-calendar-heart-48 {
    &::before {
        content: $icon-calendar-heart-48;
    }
}

.icon-gift-48 {
    &::before {
        content: $icon-gift-48;
    }
}

.icon-store-32 {
    &::before {
        content: $icon-store-32;
    }
}

.icon-truck-32 {
    &::before {
        content: $icon-truck-32;
    }
}

.icon-bolt-32 {
    &::before {
        content: $icon-bolt-32;
    }
}

.icon-badge-percent-32 {
    &::before {
        content: $icon-badge-percent-32;
    }
}

.icon-calendar-clock {
    &::before {
        content: $icon-calendar-clock;
    }
}

.icon-calendar-clock-32 {
    &::before {
        content: $icon-calendar-clock-32;
    }
}

.icon-triangle-alert {
    &::before {
        content: $icon-triangle-alert;
    }
}

.icon-move-left {
    &::before {
        content: $icon-move-left;
    }
}

.icon-share {
    &::before {
        content: $icon-share;
    }
}

.icon-arrow-up {
    &::before {
        content: $icon-arrow-up;
    }
}

.icon-chevron-up {
    &::before {
        content: $icon-chevron-up;
    }
}

.icon-zoom-in {
    &::before {
        content: $icon-zoom-in;
    }
}

.icon-sprout {
    &::before {
        content: $icon-sprout;
    }
}

.icon-bell {
    &::before {
        content: $icon-bell;
    }
}

.icon-mail-check {
    &::before {
        content: $icon-mail-check;
    }
}

.icon-badge-check {
    &::before {
        content: $icon-badge-check;
    }
}

.icon-shopping-bag {
    &::before {
        content: $icon-shopping-bag;
    }
}

.icon-phone {
    &::before {
        content: $icon-phone;
    }
}

.icon-arrow-right {
    &::before {
        content: $icon-arrow-right;
    }
}

.icon-bolt {
    &::before {
        content: $icon-bolt;
    }
}

.icon-chevron-left {
    &::before {
        content: $icon-chevron-left;
    }
}

.icon-chevron-down {
    &::before {
        content: $icon-chevron-down;
    }
}

.icon-badge-percent {
    &::before {
        content: $icon-badge-percent;
    }
}

.icon-calendar {
    &::before {
        content: $icon-calendar;
    }
}

.icon-check {
    &::before {
        content: $icon-check;
    }
}

.icon-circle-alert {
    &::before {
        content: $icon-circle-alert;
    }
}

.icon-chevron-right {
    &::before {
        content: $icon-chevron-right;
    }
}

.icon-circle-help {
    &::before {
        content: $icon-circle-help;
    }
}

.icon-circle-x {
    &::before {
        content: $icon-circle-x;
    }
}

.icon-eye-off {
    &::before {
        content: $icon-eye-off;
    }
}

.icon-info {
    &::before {
        content: $icon-info;
    }
}

.icon-map-pin {
    &::before {
        content: $icon-map-pin;
    }
}

.icon-menu {
    &::before {
        content: $icon-menu;
    }
}

.icon-message-circle-more {
    &::before {
        content: $icon-message-circle-more;
    }
}

.icon-move-right {
    &::before {
        content: $icon-move-right;
    }
}

.icon-shield-check {
    &::before {
        content: $icon-shield-check;
    }
}

.icon-shopping-cart {
    &::before {
        content: $icon-shopping-cart;
    }
}

.icon-user-round {
    &::before {
        content: $icon-user-round;
    }
}

.icon-x {
    &::before {
        content: $icon-x;
    }
}

.icon-circle-plus {
    &::before {
        content: $icon-circle-plus;
    }
}

.icon-credit-card {
    &::before {
        content: $icon-credit-card;
    }
}

.icon-headset {
    &::before {
        content: $icon-headset;
    }
}

.icon-heart {
    &::before {
        content: $icon-heart;
    }
}

.icon-arrow-down {
    &::before {
        content: $icon-arrow-down;
    }
}

.icon-move-down {
    &::before {
        content: $icon-move-down;
    }
}

.icon-package {
    &::before {
        content: $icon-package;
    }
}

.icon-check-check {
    &::before {
        content: $icon-check-check;
    }
}

.icon-megaphone {
    &::before {
        content: $icon-megaphone;
    }
}

.icon-minus {
    &::before {
        content: $icon-minus;
    }
}

.icon-pencil {
    &::before {
        content: $icon-pencil;
    }
}

.icon-plus {
    &::before {
        content: $icon-plus;
    }
}

.icon-shovel {
    &::before {
        content: $icon-shovel;
    }
}

.icon-store {
    &::before {
        content: $icon-store;
    }
}

.icon-trash-2 {
    &::before {
        content: $icon-trash-2;
    }
}

.icon-wallet {
    &::before {
        content: $icon-wallet;
    }
}

.icon-circle-check {
    &::before {
        content: $icon-circle-check;
    }
}

.icon-search {
    &::before {
        content: $icon-search;
    }
}

.icon-truck {
    &::before {
        content: $icon-truck;
    }
}

.icon-eye {
    &::before {
        content: $icon-eye;
    }
}

.icon-lock {
    &::before {
        content: $icon-lock;
    }
}

.icon-mail {
    &::before {
        content: $icon-mail;
    }
}

.icon-gift {
    &::before {
        content: $icon-gift;
    }
}

.icon-facebook {
    &::before {
        content: $icon-facebook;
    }
}

.icon-youtube {
    &::before {
        content: $icon-youtube;
    }
}

.icon-instagram {
    &::before {
        content: $icon-instagram;
    }
}

.icon-check-20 {
    &::before {
        content: $icon-check-20;
    }
}
