.store-card {
    border: 1px solid $gray-f2;
    border-radius: 8px;
    margin-bottom: 24px;
    box-shadow: 0 0 10px rgb(0 0 0 / 15%);

    .image {
        position: relative;

        img {
            width: 100%;
            max-width: 100%;
            height: 100%;
            border-radius: 8px 8px 0 0;
            object-fit: cover;
        }

        .title {
            position: absolute;
            top: 0;
            display: flex;
            width: 100%;
            height: 100%;
            flex-wrap: wrap;
            align-content: center;
            border-radius: 8px 8px 0 0;
            background: linear-gradient(0deg, rgb(0 0 0 / 40%), rgb(0 0 0 / 40%));
            gap: 24px;
            text-align: center;

            > * {
                flex-basis: 100%;
                color: $white;
            }
        }
    }

    .content {
        padding: 16px;

        .content-section {
            margin-bottom: 24px;

            &.logos {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
            }

            > * {
                margin-bottom: 0;
            }

            .inline-link {
                .icon {
                    color: $primary;
                }
            }
        }
    }

    @media #{$big-query} {
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .image {
            flex-basis: 66%;
            order: 2;

            img,
            .title {
                border-radius: 0 8px 8px 0;
            }
        }

        .content {
            flex-basis: 33%;
            align-self: center;
            padding: 40px;
        }
    }
}

.store {
    img {
        max-width: 100%;
        height: auto;
    }

    .content {
        .store-title {
            margin-top: 24px;
            text-align: center;
        }

        .content-block {
            margin-top: 24px;

            p {
                margin-bottom: 0;
            }

            .inline-link a {
                font-family: $font-base;
            }

            .icon {
                font-size: 18px;
            }
        }

        .schedule-warning {
            a {
                display: none;
            }
        }
    }

    @media #{$big-query} {
        display: flex;
        align-items: center;

        img {
            max-width: 66%;
            flex-basis: 66%;
        }

        .content {
            flex-basis: 33%;
            padding-left: 48px;

            .store-title {
                margin-top: 0;
                text-align: left;
            }
        }
    }
}

.store-inline-section {
    max-width: 648px;
    margin: 64px auto 0;

    .images {
        img {
            margin-bottom: 16px;
        }
    }

    @media #{$big-query} {
        .images {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            img {
                flex-basis: calc(50% - 8px);
                margin-bottom: 0;
            }
        }
    }
}

.store-overflow-section {
    max-width: 870px;
    margin: 64px auto 0;

    .block-image-text {
        margin-bottom: 0;
    }

    .image {
        margin-bottom: 16px;
    }

    .content {
        h4 {
            color: $gray-4f;
        }

        li {
            margin-bottom: 16px;
        }
    }

    .event-list {
        .event-card {
            .btn-contact {
                margin: 0 auto;
            }
        }
    }

    @media #{$big-query} {
        &.inverted {
            .image {
                order: 1;
            }
        }

        .image {
            order: 2;
            margin-bottom: 0;
        }

        .event-list {
            .event-card {
                flex-basis: calc(50% - 8px);
            }
        }
    }
}

.store-award {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-color: #f9f1d2;
    gap: 22px;

    @media #{$mobile-query} {
        flex-wrap: wrap;
    }

    .subtitle {
        margin-bottom: 0;
    }
}

.store-services {
    .content {
        ul {
            li {
                margin-bottom: 0;
            }
        }
    }

    .telephone-sales {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 16px;
        border-radius: 8px;
        background-color: $gray-f2;
        text-align: center;

        > * {
            flex-basis: 100%;
        }

        .btn-contact {
            margin: 0 auto;
        }
    }
}

.schedule-calendar {
    padding: 32px;
    background-color: $gray-f2;

    .calendar-heading {
        padding-bottom: 24px;
        border-bottom: 1px solid $gray-bf;
        margin-bottom: 24px;
    }

    .calendar-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;

        .icon {
            color: $gray-99;
            font-size: 40px;
        }
    }

    .calendar-content {
        display: flex;
        max-width: 500px;
        flex-wrap: wrap;
        gap: 24px;

        .calendar-item {
            width: 70px;

            @media #{$big-query} {
                width: 90px;
            }

            .title {
                font-family: $font-base-medium;
                font-size: $font-medium;
            }

            .caption {
                color: $gray-4f;
            }
        }
    }
}

.schedule-warning {
    margin-top: 20px;
    color: #5b7700;
    font-size: $font-small;
    line-height: 20px;

    &.closed {
        color: $error;
    }

    a {
        color: $success;
        text-decoration: underline;
    }
}
