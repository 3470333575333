.cart-summary-totals {
    &.shipment-info {
        padding-top: 24px;

        .title {
            display: flex;
            justify-content: space-between;

            p {
                margin-bottom: 0;
                color: $gray-33;
                font-family: $font-base-semi-bold;
                font-size: 16px;
                line-height: 20px;
            }

            a {
                color: $success;
                font-size: 14px;
                line-height: 20px;
            }
        }

        .content {
            margin-top: 8px;
            font-size: 16px;
            line-height: 24px;

            p {
                margin-bottom: 0;
            }
        }
    }

    .order-summary-section {
        border-bottom: 1px solid $gray-bf;

        &.cashback {
            .order-summary-row {
                align-items: center;

                .special-promotion-price {
                    margin-top: 0;
                }
            }
        }

        &.order-total {
            border-bottom: none;

            .order-summary-row {
                padding-bottom: 4px;
            }

            .tax {
                padding: 0;

                .title,
                .content {
                    font-family: $font-base;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        &.products {
            border-bottom: 1px solid $gray-bf;

            .product-name {
                flex-basis: 70%;
                font-family: $font-base;
            }

            .content {
                display: flex;
                flex-basis: 30%;
                justify-content: space-between;
                gap: 8px;
            }
        }

        .order-summary-row {
            display: flex;
            justify-content: space-between;
            padding: 16px 0;
            gap: 12px;

            &.shipments {
                display: block;
            }

            &.discount {
                flex-wrap: wrap;
                gap: 4px;

                .content {
                    color: $error;
                }

                .promotion,
                .description {
                    width: 100%;
                    color: $gray-33;
                }

                .promotion {
                    font-family: $font-base-semi-bold;
                }

                .description {
                    font-size: 14px;
                    line-height: 20px;

                    p {
                        margin-bottom: 0;
                    }
                }
            }

            .title {
                color: $gray-33;
                font-family: $font-base-semi-bold;
            }

            .shipment-price {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: space-between;

                .title {
                    color: $gray-33;
                    font-family: $font-base-semi-bold;
                }
            }

            .free-shipping {
                margin-top: 16px;
                font-size: $font-small;
            }

            .extra-shipping {
                display: flex;
                align-items: center;
                margin-top: 16px;
                font-family: $font-base-semi-bold;
                font-size: $font-small;
                gap: 8px;

                .icon {
                    color: $warning;
                }
            }
        }
    }

    .order-summary-section:first-of-type .order-summary-row:first-of-type {
        padding-top: 0;
    }

    .cashback-info {
        margin-top: 16px;
        font-size: $font-small;
        line-height: 20px;
    }
}
